import React from 'react';
import {Button} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {sendToPooling, sendToPoolingProgressSelector} from "../../ducks/gridList";
import {useDispatch, useSelector} from "react-redux";

const PoolingButtons = ({id, row, loadList}) => {
    const {isShowSendDraftInPoolingButton, isShowSendReservationInPoolingButton, poolingUrl} = row;

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const sendToPoolingProgress = useSelector(state => sendToPoolingProgressSelector(state));

    const open = (url) => {
        window.open(`${url || poolingUrl}`, '_blank');
    }

    const callbackSuccess = (res) => {
        loadList(false, true);
        if (res.url) open(res.url);
    }

    const send = (isDraft) => {
        dispatch(sendToPooling({id, isDraft, callbackSuccess}));
    }

    return (
        <div className='cell-buttons'>
            {
                poolingUrl && <Button
                    onClick={() => open()}
                    size='mini'
                >
                    {t('openInPoolingButton')}
                </Button>
            }
            {
                isShowSendDraftInPoolingButton && <Button
                    onClick={() => send(true)}
                    disabled={sendToPoolingProgress}
                    loading={sendToPoolingProgress}
                    size='mini'
                >
                    {t('sendDraftToPoolingButton')}
                </Button>
            }
            {
                isShowSendReservationInPoolingButton && <Button
                    onClick={() => send()}
                    disabled={sendToPoolingProgress}
                    loading={sendToPoolingProgress}
                    size='mini'
                >
                    {t('sendReservationToPoolingButton')}
                </Button>
            }
        </div>
    );
};

export default PoolingButtons;