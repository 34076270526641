import React, {useMemo} from 'react';
import Field from '../../../../components/BaseComponents';

const FormField = props => {
    const { form = {}, columnsConfig = {}, error = {}, settings = {}, name } = props;

    const newColumns = useMemo(() => {
        let newColumn = {
            ...columnsConfig[name],
        };
        if (columnsConfig[name] && columnsConfig[name].dependencies && columnsConfig[name].dependencies.length) {
                let sourceParams = {};

                columnsConfig[name].dependencies.forEach(item => {
                    sourceParams = {
                        ...sourceParams,
                        [item]:
                            form[item] && typeof form[item] === 'object'
                                ? form[item].value
                                : form[item],
                    };
                });

                newColumn = {
                    ...newColumn,
                    sourceParams,
                };
        }

        return newColumn;

    }, [columnsConfig, form]);


    return (
        <Field
            {...newColumns}
            {...props}
            key={name}
            value={form[name]}
            error={error[name]}
            settings={settings[name]}
        />
    );
};

export default React.memo(FormField);
