import React, {useEffect, useCallback, useMemo, useState} from 'react';
import { Grid, Form, Dropdown, Input, Button, Icon, TextArea, Divider, Modal} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getLookupRequest,
    listFromSelectSelector,
    progressSelector,

} from '../../../../ducks/lookup';



const CreateReturn = ({orderId, onChange}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    let initialReturn = {
        order: "",
        returnCostWithoutVAT: null,
        returnType: {},
        returnReason: {},
        items: [],
        comment: "",
      
    }

    const progress = useSelector(state => progressSelector(state));



    const reasons = useSelector(state =>
        listFromSelectSelector(state, "returnReasons", t, null, true),
    );

    
    const types = useSelector(state =>
        listFromSelectSelector(state, "returnType", t, null, true),
    );

    const articles = useSelector(state =>
        listFromSelectSelector(state, "articles", t, null, true),
    );

    const handleOpen = name => {
        if (name === "articles") {
            dispatch(
                getLookupRequest({
                    name: name,
                    orderId,
                    isForm: true,
                }),
            );
        } else {
            dispatch(
                getLookupRequest({
                    name: name,
                    isForm: true,
                }),
            );
        }
        
    }



    let [returnObject, setReturnObject] = useState(initialReturn);
    let [displayArticles, setDisplayArticles] = useState(false);
    let [itemCounter, setItemCounter] = useState(0);
    let [costWithoutVAT, setCostWithoutVAT] = useState(returnObject.returnCostWithoutVAT);

    useEffect(() => {
        const t = itemCounter;
    }, [itemCounter])

    const handleAddArticle = () => {
        const newReturn = returnObject;
        newReturn.items.push({articleId: null, quantity: null, returnReason: null});
        onChange(newReturn);
        setItemCounter(itemCounter + 1);

    }

    const handleChange = (e, {name, value, type}) => {
        const newReturn = returnObject;
        if (type === "number") {
            if (value.length <= 12) {
                setCostWithoutVAT(value);
                newReturn[name] = value;
                onChange(newReturn);
            }
        } else {
            newReturn[name] = value;
            if (newReturn.returnType.value === "partialReturnType") {
                setDisplayArticles(true);
            } else {
                newReturn.items = [];
                setDisplayArticles(false);
            }
            setItemCounter(itemCounter + 1)

            onChange(newReturn);
        }
        
    }



    const handleChangeArticle = (e, {id, name, value, inputType}) => {
        const newReturn = returnObject;
        newReturn.items.map((item, index) => {
            if (inputType === "number") {
                if ((value.length <= 10 && !value.includes(".") && !value.includes(" ") && parseInt(value) == value) || value === "") {
                    if (index === id) {
                        item[name] = value;
                    }
                }
            } else {
                if (index === id) {
                    item[name] = value;
                }
            }
            

            return item;
        });
        setItemCounter(itemCounter + 1);
        setReturnObject(newReturn);
        
    }

    const handleDeleteArticle = (e, {id, name, value, type}) => {
        const newReturn = returnObject;
        newReturn.items.splice(id, 1);
        setItemCounter(itemCounter - 1);
        setReturnObject(newReturn);
        
    }

    return (
        <>
            <Form className="tabs-card">
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t('returnReason')}</label>
                                    <Dropdown
                                        fluid
                                        selection
                                        clearable
                                        name="returnReason"
                                        text={returnObject["returnReason"].name}
                                        onOpen={() => handleOpen("returnReasons")}
                                        
                                    >
                                        <Dropdown.Menu style={{maxHeight: "calc(18vh)"}}>
                                            {reasons && reasons.length ? reasons.map(item => (
                                                <Dropdown.Item name="returnReason" text={item.name} value={item} key={item.value} onClick={handleChange}/>
                                            )) : null}
                                        </Dropdown.Menu>
                                    </Dropdown>     
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t('returnType')}</label>
                                    <Dropdown
                                        fluid
                                        selection
                                        name="returnType"
                                        onOpen={() => handleOpen("returnType")}
                                        options={types && types.length ? types.map(item => (
                                        { key: item.value, value: item, text: item.name}
                                        )) : null}
                                        onChange={handleChange}
                                    />        
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t('returnCostWithoutVAT')}</label>
                                <Input
                                    fluid
                                    type="number"
                                    value={costWithoutVAT}
                                    name="returnCostWithoutVAT"
                                    onChange={handleChange}
                                />
                            </Form.Field>
                        </Grid.Column>
                        
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t('costsComments')}</label>
                                <TextArea
                                    autoHeight
                                    name="comment"
                                    rows={2}
                                    onChange={handleChange}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    
                    {displayArticles ?
                        returnObject.items.map((article, articleIndex) =>
                        <>
                            <Button style={{marginLeft: "96%", backgroundColor: "white"}} floated="right" icon type="button" onClick={handleDeleteArticle} id={articleIndex}>
                                <Icon name="delete" />
                            </Button>  
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>{t('articleId')}</label>
                                            <Dropdown
                                                fluid
                                                selection
                                                name="articleId"
                                                value={article.articleId}
                                                id={articleIndex}
                                                onOpen={() => handleOpen("articles")}
                                                options={articles && articles.length && !progress ? articles.map(item => (
                                                    { key: item.value, value: item, text: item.name}
                                                )) : article.articleId ? [{ key: article.articleId.value, value: article.articleId, text: article.articleId.name}] : ""}
                                                onChange={handleChangeArticle}
                                            />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>{t('quantity')}</label>
                                        <Input
                                            fluid
                                            inputType="number"
                                            name="quantity"
                                            id={articleIndex}
                                            value={article.quantity || ''}
                                            onChange={handleChangeArticle}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    {articleIndex === (returnObject.items.length - 1) ?
                                        <Form.Field>
                                            <label>{t('returnReason')}</label>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    clearable
                                                    name="returnReason"
                                                    text={returnObject.items[articleIndex] && returnObject.items[articleIndex].returnReason && returnObject.items[articleIndex].returnReason.name || ''}
                                                    onOpen={() => handleOpen("returnReasons")}
                                                    
                                                >
                                                    <Dropdown.Menu style={{maxHeight: "120px"}}>
                                                        {reasons && reasons.length ? reasons.map(item => (
                                                            <Dropdown.Item id={articleIndex} name="returnReason" text={item.name} value={item} key={item.value} onClick={handleChangeArticle}/>
                                                        )) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>     
                                        </Form.Field> :
                                        <Form.Field>
                                            <label>{t('returnReason')}</label>
                                            <Dropdown
                                                fluid
                                                selection
                                                name="returnReason"
                                                value={article.returnReason || ''}
                                                id={articleIndex}
                                                clearable
                                                onOpen={() => handleOpen("returnReasons")}
                                                options={reasons && reasons.length ? reasons.map(item => (
                                                    { key: item.value, value: item, name: item.value, text: item.value}
                                                )) : null}
                                                onChange={handleChangeArticle}
                                            />                                
                                        </Form.Field>
                                    } 
                                </Grid.Column>
                            </Grid.Row>
                        </>
                        )                        
                    : null}
                    {displayArticles ?
                            <Grid.Row>
                                <Button style={{backgroundColor: "white", marginBottom: "30px"}} floated="right" size="massive" icon 
                                    onClick={handleAddArticle} type="button">
                                        <Icon name="add circle" />
                                </Button>
                             </Grid.Row>                     
                        : null}
                </Grid>
            </Form>
        </>
    );
};

export default CreateReturn;