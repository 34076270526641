import { createSelector } from 'reselect';
import { downloader, postman } from '../utils/postman';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { representationFromGridSelector } from './representations';
import {showModal} from "./modal";
import downloadFile from "../utils/downloadFile";
import downloadFileByData from "../utils/downloadFileByData";

//*  TYPES  *//

const GET_IDS_REQUEST = 'GET_IDS_REQUEST';
const GET_IDS_SUCCESS = 'GET_IDS_SUCCESS';
const GET_IDS_ERROR = 'GET_IDS_ERROR';

const GET_ACTIONS_REQUEST = 'GET_ACTIONS_REQUEST';
const GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS';
const GET_ACTIONS_ERROR = 'GET_ACTIONS_ERROR';

const INVOKE_ACTION_REQUEST = 'INVOKE_ACTION_REQUEST';
const INVOKE_ACTION_SUCCESS = 'INVOKE_ACTION_SUCCESS';
const INVOKE_ACTION_ERROR = 'INVOKE_ACTION_ERROR';

const INVOKE_MASS_UPDATE_REQUEST = 'INVOKE_MASS_UPDATE_REQUEST';
const INVOKE_MASS_UPDATE_SUCCESS = 'INVOKE_MASS_UPDATE_SUCCESS';
const INVOKE_MASS_UPDATE_ERROR = 'INVOKE_MASS_UPDATE_ERROR';

const SHIPPING_REGISTERS_CONFIG_REQUEST = 'SHIPPING_REGISTERS_CONFIG_REQUEST';
const SHIPPING_REGISTERS_CONFIG_SUCCESS = 'SHIPPING_REGISTERS_CONFIG_SUCCESS';
const SHIPPING_REGISTERS_CONFIG_ERROR = 'SHIPPING_REGISTERS_CONFIG_ERROR';

const EXPORT_SHIPPING_REGISTERS_REQUEST = 'EXPORT_SHIPPING_REGISTERS_REQUEST';
const EXPORT_SHIPPING_REGISTERS_SUCCESS = 'EXPORT_SHIPPING_REGISTERS_SUCCESS';
const EXPORT_SHIPPING_REGISTERS_ERROR = 'EXPORT_SHIPPING_REGISTERS_ERROR';

const CLEAR_ACTIONS = 'CLEAR_ACTIONS';

const DEFAULT_STATE = 'DEFAULT_STATE';

//*  INITIAL STATE  *//

const initial = {
    actions: [],
    actionsCard: [],
    info: {},
    updates: [],
    ids: [],
    shippingRegistersConfig: {},
    progress: false,
    progressActionName: null,
    progressMassUpdate: false,
    progressShippingRegistersConfig: false,
    progressShippingRegistersExport: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_IDS_REQUEST:
            return {
                ...state,
                actions: [],
                info: [],
                updates: [],
            };
        case GET_IDS_SUCCESS:
            return {
                ...state,
                ids: payload
            };
        case GET_ACTIONS_REQUEST:
            return {
                ...state,
                actions: [],
                progress: true
            };
        case GET_ACTIONS_SUCCESS:
            let stateNew = {
                ...state,
                info: payload.info,
                updates: payload.updates,
                progress: false,
            };

            if (payload.isCard) {
                stateNew = {
                    ...stateNew,
                    actionsCard: payload.actions,
                };
            } else {
                stateNew = {
                    ...stateNew,
                    actions: payload.actions,
                };
            }

            return stateNew;
        case GET_ACTIONS_ERROR:
            return {
                ...state,
                actions: [],
                progress: false
            };
        case INVOKE_ACTION_REQUEST:
            return {
                ...state,
                progressActionName: payload.actionName,
            };
        case INVOKE_ACTION_SUCCESS:
            return {
                ...state,
                progressActionName: null,
            };
        case INVOKE_ACTION_ERROR:
            return {
                ...state,
                progressActionName: null,
            };
        case INVOKE_MASS_UPDATE_REQUEST:
            return {
                ...state,
                progressMassUpdate: true,
            };
        case INVOKE_MASS_UPDATE_SUCCESS:
        case INVOKE_MASS_UPDATE_ERROR:
            return {
                ...state,
                progressMassUpdate: false,
            };
        case SHIPPING_REGISTERS_CONFIG_REQUEST:
            return {
                ...state,
                progressShippingRegistersConfig: true,
            }
        case SHIPPING_REGISTERS_CONFIG_SUCCESS:
            return {
                ...state,
                shippingRegistersConfig: payload,
                progressShippingRegistersConfig: false,
            }
        case SHIPPING_REGISTERS_CONFIG_ERROR:
            return {
                ...state,
                shippingRegistersConfig: {},
                progressShippingRegistersConfig: false,
            }
        case EXPORT_SHIPPING_REGISTERS_REQUEST:
            return {
                ...state,
                progressShippingRegistersExport: true,
            }
        case EXPORT_SHIPPING_REGISTERS_SUCCESS:
        case EXPORT_SHIPPING_REGISTERS_ERROR:
            return {
                ...state,
                progressShippingRegistersExport: false,
            }
        case CLEAR_ACTIONS:
            return {
                ...state,
                actions: [],
                info: {},
                updates: [],
                actionsCard: [],
                ids: [],
            };
        case DEFAULT_STATE:
            return {
                ...initial
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getActionsRequest = payload => {
    return {
        type: GET_ACTIONS_REQUEST,
        payload,
    };
};

export const invokeActionRequest = payload => {
    return {
        type: INVOKE_ACTION_REQUEST,
        payload,
    };
};

export const shippingRegistersConfigRequest = payload => {
    return {
        type: SHIPPING_REGISTERS_CONFIG_REQUEST,
        payload,
    }
}

export const exportShippingRegistersRequest = payload => {
    return {
        type: EXPORT_SHIPPING_REGISTERS_REQUEST,
        payload,
    }
}

export const clearActions = () => {
    return {
        type: CLEAR_ACTIONS,
    };
};

export const getAllIdsRequest = payload => {
    return {
        type: GET_IDS_REQUEST,
        payload,
    };
};

export const invokeMassUpdateRequest = payload => {
    return {
        type: INVOKE_MASS_UPDATE_REQUEST,
        payload,
    };
};

export const clearAllIdsRequest = payload => {
    return {
        type: GET_IDS_SUCCESS,
        payload
    }
};

//*  SELECTORS *//

const stateSelector = state => state.gridActions;

export const actionsSelector = createSelector(stateSelector, state =>
    state.actions.map(item => ({
        ...item,
        ids: item.ids || [],
    })),
);

export const actionsCardSelector = createSelector(stateSelector, state =>
    (state.actionsCard || []).map(item => ({
        ...item,
        ids: item.ids || [],
    })),
);

export const progressSelector = createSelector(stateSelector, state => state.progress);

export const progressActionNameSelector = createSelector(
    stateSelector,
    state => state.progressActionName,
);

export const infoSelector = createSelector(stateSelector, state => state.info);
export const updatesSelector = createSelector(stateSelector, state => state.updates);
export const progressMassUpdateSelector = createSelector(
    stateSelector,
    state => state.progressMassUpdate,
);
export const progressShippingRegistersConfigSelector = createSelector(stateSelector, state => state.progressShippingRegistersConfig);
export const progressShippingRegistersExportSelector = createSelector(stateSelector, state => state.progressShippingRegistersExport);
export const shippingRegistersConfigSelector = createSelector(stateSelector, state => state.shippingRegistersConfig);

export const allIdsSelector = createSelector(stateSelector, state => state.ids);

//*  SAGA  *//

function* getActionsSaga({ payload }) {
    try {
        const { name, ids = [], isCard } = payload;
        if (ids.length) {
            const actions = yield postman.post(`/${name}/getActions`, ids);
            let info = {};
            let updates = [];

            if (!isCard) {
                info = yield postman.post(`/${name}/getSummary`, ids);
                updates = yield postman.post(`/${name}/getBulkUpdates`, ids);
            }
            yield put({
                type: GET_ACTIONS_SUCCESS,
                payload: { actions, info, updates, isCard },
            });
        } else {
            yield put({
                type: GET_ACTIONS_SUCCESS,
                payload: { actions: [], info: {}, update: [], actionsCard: [] },
            });
        }
    } catch (e) {
        yield put({ type: GET_ACTIONS_ERROR });
    }
}

function* invokeActionSaga({ payload }) {
    try {
        const { ids, callbackSuccess, name, actionName, model, callbackError, callbackConfirmation, confirmed } = payload;
        const result = yield postman.post(`/${name}/invokeAction/${actionName}${confirmed ? "/confirmed" : ""}`, { ids, model });

        if (model) {
            if (result.needConfirmation) {
                yield put({
                    type: INVOKE_ACTION_SUCCESS,
                });
                callbackConfirmation && callbackConfirmation(result.confirmationMessage, model, callbackSuccess)
            } else {
                if (result.isError) {
                    callbackError && callbackError(result.errors);
                    toast.error(result.message);
                } else if (result.fileContent) {
                    downloadFileByData(result.fileName, result.fileType, result.fileContent);
                } else {
                    toast.info(result.message);
                    yield put({
                        type: INVOKE_ACTION_SUCCESS,
                    });
                    callbackSuccess && callbackSuccess();
                }
            }
            
        } else {
            if (result.needConfirmation) {
                yield put({
                    type: INVOKE_ACTION_SUCCESS,
                });
                callbackConfirmation && callbackConfirmation(result.confirmationMessage);
            } else {
                yield put({
                    type: INVOKE_ACTION_SUCCESS,
                });
                if (result.fileContent) {
                    downloadFileByData(result.fileName, result.fileType, result.fileContent);
                } else if (result.messageType === 0) {
                    toast.info(result.message, {
                        autoClose: !result.manuallyClosableMessage,
                    });
                } else {
                    yield put(showModal(result));
                }
    
                callbackSuccess && callbackSuccess();
            }
            
        }
    } catch (e) {
        yield put({
            type: INVOKE_ACTION_ERROR,
            payload: e,
        });
    }
}

function* getAllIdsSaga({ payload }) {
    try {
        const { filter, name, callbackSuccess } = payload;
        const representation = yield select(state => representationFromGridSelector(state, name));
        const columns = representation ? representation.map(item => item.name) : [];
        const result = yield postman.post(`/${name}/ids`, {
            ...filter,
            filter: {
                ...filter.filter,
                columns,
            },
        });

        yield put({
            type: GET_IDS_SUCCESS,
            payload: result
        });

        callbackSuccess && callbackSuccess(result);
    } catch (e) {
        yield put({
            type: GET_IDS_ERROR,
            payload: e,
        });
    }
}

function* invokeMassUpdateSaga({ payload }) {
    try {
        const { ids, callbackSuccess, name, field, value } = payload;
        const result = yield postman.post(`/${name}/invokeBulkUpdate/${field}`, {
            ids,
            value: value && typeof value === 'object' ? value.value : value,
        });
        if (result.isError) {
            toast.error(result.message);
            yield put({
                type: INVOKE_MASS_UPDATE_ERROR,
                payload: result,
            });
        } else {
            yield put({
                type: INVOKE_MASS_UPDATE_SUCCESS,
            });
            result.message && toast.info(result.message);
        }
        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: INVOKE_MASS_UPDATE_ERROR,
            payload: e,
        });
    }
}

function* shippingRegistersConfigSaga({ payload }) {
    try {
        const { callbackSuccess } = payload;
        const result = yield postman.get(`/shippingRegisters/formConfiguration`);

        yield put({
            type: SHIPPING_REGISTERS_CONFIG_SUCCESS,
            payload: result
        });

        callbackSuccess && callbackSuccess(result);
    } catch (e) {
        yield put({
            type: SHIPPING_REGISTERS_CONFIG_ERROR,
            payload: e,
        });
    }
}

function* exportShippingRegistersSaga({ payload }) {
    try {
        const { form, callbackSuccess } = payload
        const res = yield downloader.post(
            `/shippingRegisters/exportToExcel`,
            form,
            { responseType: 'blob' },
        );

        downloadFile(res);
        yield put({ type: EXPORT_SHIPPING_REGISTERS_SUCCESS });

        callbackSuccess && callbackSuccess();
    } catch (error) {
        yield put({ type: EXPORT_SHIPPING_REGISTERS_ERROR });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_ACTIONS_REQUEST, getActionsSaga),
        takeEvery(INVOKE_ACTION_REQUEST, invokeActionSaga),
        takeEvery(GET_IDS_REQUEST, getAllIdsSaga),
        takeEvery(INVOKE_MASS_UPDATE_REQUEST, invokeMassUpdateSaga),
        takeEvery(SHIPPING_REGISTERS_CONFIG_REQUEST, shippingRegistersConfigSaga),
        takeEvery(EXPORT_SHIPPING_REGISTERS_REQUEST, exportShippingRegistersSaga),
    ]);
}
