import React, { useRef } from 'react';
import { dataLoadingRequest } from '../../ducks/loadingData';
import {downloadDocumentRequest, uploadFileRequest} from '../../ducks/documents';
import { useDispatch } from 'react-redux';
import { Input, Form, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';

const File = ({
    value,
    name,
    onChange,
    onBlur,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    type,
    datalist: valuesList = [],
    placeholder,
    isRequired,
    autoComplete,
    autoFocus,
    isGrid,
}) => {
    const { t } = useTranslation();
    const fileUploader = useRef(null);
    const dispatch = useDispatch();

    const onFilePicked = e => {
        let form = new FormData();
        let file = e.target.files[0];
        form.append('formFile', file);
        if (file.size / 1024 / 1024 <= 10) {
            dispatch(
                uploadFileRequest({
                    form,
                    callbackSuccess: id => {
                        onChange(e, {
                            name,
                            value: {
                                value: id,
                                name: file.name,
                            },
                        });
                    },
                }),
            );
            fileUploader.current.value = null;
        } else {
            toast.error(t("error_file_size"), {
                autoClose: false,
            });
        }

       
    };

    const handleDownload = () => {
        value && value.value && dispatch(downloadDocumentRequest({
            id: value.value
        }))
    };

    console.log(error);

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            <div className={error === undefined ? "field-file": "field-file field-file_error"}>
                <div
                    className={error === undefined ? "field-file_link": "field-file_link field-file_link-error"}
                    onClick={handleDownload}
                >
                    {value && typeof value === 'object' ? value.name : value}
                </div>
                <Button
                    disabled={isDisabled}
                    icon
                    onClick={() => {
                        fileUploader && fileUploader.current.click();
                    }}
                >
                    <Icon name="upload" />
                </Button>
            </div>
            <input
                type="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                onChange={onFilePicked}
            />
            { error !== undefined ? <span className="label-error">{error}</span> : ""}
        </Form.Field>
    );
};

export default File;
