import React, {useCallback, useState, useEffect} from 'react';
import { Grid, Form, Dropdown, Input, Button, Icon, TextArea, Divider, Modal, Segment} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import CreateReturn from './createReturn';
import { SHIPPINGS_GRID } from '../../../../constants/grids';
import { useDispatch, useSelector } from 'react-redux';
import { columnsTypesConfigSelector } from '../../../../ducks/representations';
import {
    getLookupRequest,
    listFromSelectSelector,
    progressSelector,

} from '../../../../ducks/lookup';

const Return = ({ form = {}, onChange, settings, error }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID));
    let [filter, setFilter] = useState('');
    let [chosenSource, setSource] = useState(null);
    let [openCreateReturn, setOpenCreateReturn] = useState(false);
    let [currentOrder, setCurrentOrder] = useState("");

    const reasonsValuesList = useSelector(state =>
        listFromSelectSelector(state, "returnReasons", t, filter, true),
    );
   
    const typeValuesList = useSelector(state =>
        listFromSelectSelector(state, "returnType", t, filter, true),
    );

    const articlesValuesList = useSelector(state =>
        listFromSelectSelector(state, "articles", t, filter, true),
    );
    const progress = useSelector(state => progressSelector(state));

    useEffect(() => {
            dispatch(
                getLookupRequest({
                    name: "returnType",
                    isForm: true,
                }),
            );
            dispatch(
                getLookupRequest({
                    name: "returnReasons",
                    isForm: true,
                }),
            );
        
      }, [form]);


    
    const handleChangeNumber = (e, {id, name, value}) => {
        if (value.length <= 12) {
            handleChange(e, {id, name, value});
        }
    }
      

    const handleChange = useCallback((e, { id, name, value }) => {
        const newOrderCosts = form.orderCosts.map((item, index) => {
            if (index === id) {
                item[name] = value;
            }
            return item;
        })
        onChange(e, {
            name: 'orderCosts',
            value: newOrderCosts
        })

    }, [form]);

    const handleAddArticle = useCallback((e, { id }) => {
        const newOrderCosts = form.orderCosts.map((item, index) => {
            if (index === id) {
                item.items.push({articleId: null, quantity: null, returnReason: null});
            }
            return item;
        })
        onChange(e, {
            name: 'orderCosts',
            value: newOrderCosts
        })

    }, [form]);

    const handleDeleteArticle = useCallback((e, { id }) => {
        const newOrderCosts = form.orderCosts.map((item, index) => {
            if (index === id.parentIndex) {
                item.items.forEach((article, aIndex) => {
                    if (aIndex === id.childIndex) {
                        item.items.splice(aIndex, 1);
                    }
                })
            }
            return item;
        })
        onChange(e, {
            name: 'orderCosts',
            value: newOrderCosts
        })

    }, [form]);

    const handleChangeArticle = useCallback((e, { id, name, value }) => {
        const newOrderCosts = form.orderCosts.map((item, index) => {
            if (index === id.parentIndex) {
                item.items.map((article, aIndex) => {
                    if (aIndex === id.childIndex) {
                        article[name] = value;
                    }
                    return article;
                })
            }
            return item;
        })
        onChange(e, {
            name: 'orderCosts',
            value: newOrderCosts
        })

    }, [form]);
    
    const handleChangeArticleNumber = (e, { id, name, value }) => {
        if ((value.length <= 10 && !value.includes(".") && !value.includes(" ") && parseInt(value) == value) || value === "") {
            handleChangeArticle(e, {id, name, value});
        }
    };

    const handleAddReturn = useCallback((e) => {
        const newOrderCosts = form.orderCosts;
        let initialReturn = {
            order: "",
            returnCostWithoutVAT: null,
            returnType: null,
            returnReason: null,
            items: [],
            comment: "",
          
        }
        newOrderCosts.push(initialReturn);
        onChange(e, {
            name: 'orderCosts',
            value: newOrderCosts
        });



    }, [form]);

    const handleDeleteReturn = useCallback((e, {index}) => {
        const newOrderCosts = form.orderCosts;

        if (index !== 0) {
            newOrderCosts.splice(index, 1);
        } else {
            newOrderCosts.splice(index, 1);
        }

       
        
        onChange(e, {
            name: 'orderCosts',
            value: newOrderCosts
        });
    }, [form]);

    const handleOpen = (orderId) => {
        if (orderId) {
            dispatch(
                getLookupRequest({
                    name: "articles",
                    orderId,
                    isForm: true,
                }),
            );
        } 
    }

    const articleOptionsList = (article) => {
        if (articlesValuesList && articlesValuesList.length !== 0 && !progress) {
            if (article && article.articleId && !articlesValuesList.find(item => item.value === article.articleId.value)) {
                return [{ key: article.articleId.value, value: article.articleId, text: article.articleId.name}];
            }
            return articlesValuesList.map(item => (
                { key: item.value, value: item, text: item.name}
            ));  
        } else if (article && article.articleId) {
            return [{ key: article.articleId.value, value: article.articleId, text: article.articleId.name}];
        } else {
            return null;
        }
    }

    return (
        <Form className="tabs-card">
                        {form.orderCosts && form.orderCosts.map( (returnItem, index) => {
                            return (
                                <Grid>
                                    <Button size="large" style={{marginLeft: "96%", backgroundColor: "white"}} floated="right" icon onClick={e => handleDeleteReturn(e, {index})}>
                                        <Icon name="delete" />
                                    </Button>   
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>{t('orderNumber')}</label>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    name="order"
                                                    id={index}
                                                    value={returnItem.order}
                                                    options={form.orders && form.orders.length ? form.orders.map(item => (
                                                        { key: item.id, value: {value: item.id, name: item.orderNumber, isFilterOnly: false, isBulkUpdateOnly: false}, 
                                                        name: item.orderNumber, text: item.orderNumber}
                                                    )) : null}
                                                    onChange={handleChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>{t('returnReason')}</label>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    clearable
                                                    name="returnReason"
                                                    value={returnItem.returnReason}
                                                    id={index}
                                                    options={reasonsValuesList && reasonsValuesList.length ? reasonsValuesList.map(item => (
                                                        { key: item.value, value: item, name: item.value, text: item.value}
                                                    )) : null}
                                                    onChange={handleChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>{t('returnType')}</label>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    name="returnType"
                                                    value={returnItem.returnType}
                                                    id={index}
                                                    options={typeValuesList && typeValuesList.length ? typeValuesList.map(item => (
                                                        { key: item.value, value: item, text: item.name, isItemsRequired: item.isItemsRequired }
                                                    )) : null}
                                                    onChange={handleChange}
                                                >
                                                </Dropdown>
                                                
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                                <Form.Field>
                                                    <label>{t('returnCostWithoutVAT')}</label>
                                                    <Input
                                                        fluid
                                                        type="number"
                                                        name="returnCostWithoutVAT"
                                                        id={index}
                                                        value={returnItem.returnCostWithoutVAT || ''}
                                                        onChange={handleChangeNumber}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Form.Field>
                                                    <label>{t('costsComments')}</label>
                                                    <TextArea
                                                        autoHeight
                                                        name="comment"
                                                        rows={2}
                                                        id={index}
                                                        value={returnItem.comment || ''}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                    </Grid.Row>
                                    {returnItem && returnItem.returnType && returnItem.returnType.value === "partialReturnType" ?
                                    <>
                                        <label style={{marginTop: "20px"}}>{t("articles")}</label>
                                        <Segment className="costs-materials">
                                            {returnItem && returnItem.items && returnItem.items.length !== 0 && returnItem.items.map((article, articleIndex) =>
                                                    <Grid>
                                                        <Button style={{marginLeft: "96%", backgroundColor: "white"}} floated="right" icon onClick={handleDeleteArticle} id={{parentIndex: index, childIndex: articleIndex}}>
                                                            <Icon name="delete" />
                                                        </Button>   
                                                        <Grid.Row columns={3}>
                                                            <Grid.Column>
                                                                <Form.Field>
                                                                    <label>{t('articleId')}</label>
                                                                    <Dropdown
                                                                        fluid
                                                                        selection
                                                                        name="articleId"
                                                                        value={article.articleId || null}
                                                                        id={{parentIndex: index, childIndex: articleIndex}}
                                                                        options={articleOptionsList(article ? article : null)}
                                                                        onOpen={() => 
                                                                            handleOpen(returnItem.order.value)}
                                                                        onChange={handleChangeArticle}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Form.Field>
                                                                    <label>{t('quantity')}</label>
                                                                    <Input
                                                                        fluid
                                                                        name="quantity"
                                                                        id={{parentIndex: index, childIndex: articleIndex}}
                                                                        value={article.quantity || ''}
                                                                        onChange={handleChangeArticleNumber}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Form.Field>
                                                                    <label>{t('returnReason')}</label>
                                                                    <Dropdown
                                                                        fluid
                                                                        selection
                                                                        clearable
                                                                        name="returnReason"
                                                                        value={article.returnReason || ''}
                                                                        id={{parentIndex: index, childIndex: articleIndex}}
                                                                        options={reasonsValuesList && reasonsValuesList.length !== 0 ? reasonsValuesList.map(item => (
                                                                            { key: item.value, value: item, name: item.value, text: item.value}
                                                                        )) : article && article.returnReason ? [{ key: article.returnReason.value, value: article.returnReason , text: article.returnReason.name}] : null}
                                                                        onChange={handleChangeArticle}
                                                                    />                                                                  
                                                                </Form.Field>
                                                            </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>    
                                                )}  
                                            <Grid>
                                                <Grid.Row>
                                                    <Button style={{backgroundColor: "white"}} floated="right" size="massive" icon 
                                                    onClick={handleAddArticle} id={index}>
                                                        <Icon name="add circle" />
                                                    </Button>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>   
                                    </>    
                                    : null}                      
                                </Grid>
                            )
                        })}
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width={15}>  
                                <Divider section></Divider>
                            </Grid.Column>
                            <Grid.Column width={1}>
                                <Button style={{backgroundColor: "white"}} floated="right" size="massive" icon onClick={e => handleAddReturn(e)}>
                                    <Icon name="add" />
                                </Button>   
                            </Grid.Column>
                        </Grid.Row>
                    </Grid> 
                    <Modal open={openCreateReturn} closeOnDimmerClick={true} closeOnEscape={true} onClose={() => setOpenCreateReturn(false)}>
                        <Modal.Content scrolling>
                            <CreateReturn temporaryId={form.orderCosts && form.orderCosts.length + 1} reasons={reasonsValuesList} articles={articlesValuesList} 
                            types={typeValuesList} handleClose={(() => setOpenCreateReturn(false))} handleSave={handleAddReturn}/>
                        </Modal.Content>  
                    </Modal>
        </Form>
    );
};

export default Return;
