import React, { useCallback } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import FormField from '../../../../components/BaseComponents';
import {
    BIG_TEXT_TYPE,
    DATE_TIME_TYPE,
    NUMBER_TYPE,
    SELECT_TYPE,
    STATE_TYPE,
} from '../../../../constants/columnTypes';
import {clearError, settingsExtSelector} from '../../../../ducks/gridCard';

const Route = ({
    name,
    form = {},
    point = {},
    onChange,
    pointChange,
    index,
    settings: baseSettings,
    error,
}) => {
    const settings = useSelector(state => settingsExtSelector(state, form.status));
    const dispatch = useDispatch();

    const handleChange = useCallback(
        (e, { name, value }) => {
            if (error[`${name}_${index}`]) {
                dispatch(clearError && clearError(`${name}_${index}`));
                const e = Object.keys(error).filter(i => i.includes('plannedDate'));

                if (e && e.length) {
                  e.forEach(i => {
                      dispatch(clearError && clearError(i));
                  })
                }
            }
            pointChange(
                {
                    ...point,
                    [name]: value,
                },
                index,
            );
        },
        [point, error],
    );

    return (
        <Form style={{ paddingLeft: '12px' }}>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <FormField
                            name="plannedDate"
                            text={point.isLoading ? 'plannedDate_loading' : 'plannedDate_delivery'}
                            error={error[`plannedDate_${index}`]}
                            value={point['plannedDate']}
                            type={DATE_TIME_TYPE}
                            settings={settings['plannedDate']}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="vehicleStatus"
                            value={point['vehicleStatus']}
                            source="vehicleState"
                            error={error[`vehicleStatus_${index}`]}
                            type={STATE_TYPE}
                            settings={settings['vehicleStatus']}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <FormField
                            name="arrivalTime"
                            value={point['arrivalTime']}
                            settings={settings['arrivalTime']}
                            error={error[`arrivalTime_${index}`]}
                            maxDate={point['departureTime']}
                            type={DATE_TIME_TYPE}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="departureTime"
                            value={point['departureTime']}
                            settings={settings['departureTime']}
                            error={error[`departureTime_${index}`]}
                            minDate={point['arrivalTime']}
                            type={DATE_TIME_TYPE}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <FormField
                            name="address"
                            value={point['address']}
                            settings={settings['address']}
                            error={error[`address_${index}`]}
                            type={BIG_TEXT_TYPE}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={18}>
                        <FormField
                            name="deviationReasonsComments"
                            type={BIG_TEXT_TYPE}
                            value={form['deviationReasonsComments']}
                            settings={baseSettings['deviationReasonsComments']}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                {point.isLoading ? 
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormField
                                name="problemStatus"
                                type={SELECT_TYPE}
                                source="problemStatuses"
                                sourceParams={{status: form['status']}}
                                value={form['problemStatus']}
                                settings={baseSettings['problemStatus']} 
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="loadingDowntimeReason"
                                value={point['loadingDowntimeReason']}
                                error={error[`loadingDowntimeReason_${index}`]}
                                settings={settings['loadingDowntimeReason']}
                                source="downtimeReasons"
                                type={SELECT_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    :
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormField
                                name="problemStatus"
                                type={SELECT_TYPE}
                                source="problemStatuses"
                                sourceParams={{status: form['status']}}
                                value={form['problemStatus']}
                                settings={baseSettings['problemStatus']} 
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="trucksDowntime"
                                value={point['trucksDowntime']}
                                error={error[`trucksDowntime_${index}`]}
                                settings={settings['trucksDowntime']}
                                type={NUMBER_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    }
                {!point.isLoading && 
                <Grid.Row columns={2}>
                    <Grid.Column>
                            <FormField
                                name="unloadingDowntimeReason"
                                value={point['unloadingDowntimeReason']}
                                error={error[`unloadingDowntimeReason_${index}`]}
                                settings={settings['unloadingDowntimeReason']}
                                source="downtimeReasons"
                                type={SELECT_TYPE}
                                onChange={handleChange}
                            />
                    </Grid.Column>
                </Grid.Row>}
            </Grid>
        </Form>
    );
};

export default Route;
