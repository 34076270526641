import React from 'react';
import { Form, Input, Popup, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Text = ({
    value,
    name,
    onChange,
    onBlur,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    type,
    datalist: valuesList = [],
    placeholder,
    isRequired,
    autoComplete,
    autoFocus,
    isGrid,
    promptKey,
}) => {
    const { t } = useTranslation();

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            <div className="input-prompt-container">
                <Input
                    placeholder={t(placeholder)}
                    list={valuesList && name}
                    className={className}
                    type={type}
                    disabled={isDisabled}
                    name={name}
                    value={value}
                    error={error}
                    onChange={onChange}
                    onBlur={onBlur}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                    transparent={isGrid}
                    style={isGrid && value ? {width: `${(value.length + 1) * 8 + 24}px`} : null}
                />
                <Popup basic disabled={!promptKey} content={t(promptKey)} on='hover' trigger={
                    <Icon className="prompt-icon" name={promptKey ? "question circle outline" : null} color='light grey'/>
                }/>
            </div>
            
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
            {valuesList && valuesList.length ? (
                <datalist id={name}>
                    {valuesList.map(item => (
                        <option key={item.id} value={item.name} />
                    ))}
                </datalist>
            ) : null}
        </Form.Field>
    );
};
export default Text;
