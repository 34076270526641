import React from 'react';
import { Form, Input, Popup, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Number = ({
    value = '',
    name,
    onChange,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    placeholder,
    isRequired,
    isInteger,
    autoFocus,
    isGrid,
    promptKey
}) => {
    const { t } = useTranslation();

    const handleOnChange = (e, { value }) => {
        
        if (isInteger) {
            if (value.length <= 9) {
                onChange(e, {
                    name,
                    value: value.toString().replace(/[^\d-]/g, ''),
                });
            } else {
                let cutValue = value.toString().replace(/[^\d-]/g, '').slice(0, 9);
                onChange(e, {
                    name,
                    value: cutValue,
                });
            }
            
        }

        if (!isInteger) {
            if (value.length <= 12) {
                onChange(e, {
                    name,
                    value: value.toString(),
                });
            } else {
                let cutValue = value.toString().slice(0, 12);
                onChange(e, {
                    name,
                    value: cutValue,
                });
            }
            
        }
       
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: `${t(text || name)}${isRequired ? ' *' : ''}` }} />
                </label>
            ) : null}
            <div className="input-prompt-container">
                <Input
                    placeholder={t(placeholder)}
                    className={className}
                    type={isInteger ? 'text' : 'number'}
                    error={error}
                    disabled={isDisabled || false}
                    name={name}
                    value={value}
                    step="any"
                    transparent={isGrid}
                    autoFocus={autoFocus}
                    onChange={handleOnChange}
                    autoComplete="off"
                />
                <Popup basic disabled={!promptKey} content={t(promptKey)} on='hover' trigger={
                    <Icon className="prompt-icon" name={promptKey ? "question circle outline" : null} color='light grey'/>
                }/>
        </div>
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    );
};
export default Number;
