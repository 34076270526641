import React, { useState } from 'react';
import { Form, Icon, Input, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const PasswordField = ({
    value = '',
    onChange,
    noLabel,
    isDisabled,
    isRequired,
    text,
    name,
    onBlur,
    error,
    promptKey,
}) => {
    let [show, setShow] = useState(false);
    const { t } = useTranslation();

    const toggle = () => {
        setShow(prevState => !prevState);
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: `${t(text || name)}${isRequired ? ' *' : ''}` }} />
                </label>
            ) : null}
            <div className="input-prompt-container">
                <Input
                    value={value}
                    fluid
                    name={name}
                    error={error}
                    type={show ? 'text' : 'password'}
                    icon={<Icon name={show ? 'eye slash' : 'eye'} link onClick={toggle} />}
                    autoComplete="new-password"
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <Popup basic disabled={!promptKey} content={t(promptKey)} on='hover' trigger={
                    <Icon className="prompt-icon" name={promptKey ? "question circle outline" : null} color='light grey'/>
                }/>
            </div>
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    );
};

export default PasswordField;
