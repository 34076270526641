import React from 'react';
import {Button, Modal} from 'semantic-ui-react';
import './style.scss';
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

const ConfirmDialog = ({content, open, onCancel, onConfirm, confirmButton = "Ok", cancelButton, dimmer = "blurring"}) => {

    const handleConfirm = () => {
        onConfirm();
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <Modal open={open} size="small" dimmer={dimmer}>
            <Modal.Content>
                <p>{content}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCancel}>
                    {cancelButton}
                </Button>
                <Button autoFocus color="blue" onClick={handleConfirm}>
                    {confirmButton}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ConfirmDialog;
