import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, Form, Popup, Icon } from 'semantic-ui-react';

import './style.scss';
import {getLookupRequest, listFromMultiSelect, listFromSelectSelector, valuesListSelector} from '../../ducks/lookup';

const MultiSelect = ({
    value,
    onChange,
    placeholder = '',
    isDisabled,
    label,
    name,
    text,
    multiple,
    loading,
    clearable,
    source,
    isTranslate,
    error,
    textValue,
    noLabel,
    isRequired,
    autoComplete,
    children,
    upward,
    checkForClickedPosition,
    sourceParams,
    promptKey,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let [openUpward, setOpenUpward] = useState(undefined);

    useEffect(() => {
        dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
                sourceParams,
            }),
        );
    }, []);

    const valuesList = useSelector(state =>
        listFromMultiSelect(state, source, isTranslate, t),
    );

    const handleChange = (e, { value }) => {
        onChange(e, { value: value ? valuesList.filter(item => value.includes(item.value)) : null, name });
    };

    const handleSearch = (options, query) => {
        return options.filter(item => item && item.text.replaceAll(' ', '').includes(query.replaceAll(' ', '')));
    };

    const handleFocus = () => {
        checkForClickedPosition && setOpenUpward(checkForClickedPosition);
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>{`${t(text || name)}${
                    isRequired ? ' *' : ''
                }`}</label>
            ) : null}
            <div className="input-prompt-container">
                <div className="form-select">
                    <Dropdown
                        placeholder={t(placeholder)}
                        fluid
                        deburr
                        clearable={clearable}
                        selection
                        loading={loading}
                        text={textValue}
                        error={error}
                        multiple
                        disabled={isDisabled}
                        value={value ? value.map(item => item.value) : null}
                        options={valuesList}
                        onChange={handleChange}
                        selectOnBlur={false}
                        autoComplete={autoComplete}
                        onFocus={handleFocus}
                        search={handleSearch}
                        upward={upward ? true : openUpward === false ? false : openUpward === true ? true : false}
                    />
                    {children && children}
                </div>
                <Popup basic disabled={!promptKey} content={t(promptKey)} on='hover' trigger={
                    <Icon className="prompt-icon" name={promptKey ? "question circle outline" : null} color='light grey'/>
                }/>
            </div>
            {error && typeof error === 'string' && <span className="label-error">{error}</span>}
        </Form.Field>
    );
};

export default React.memo(MultiSelect);
